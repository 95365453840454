<template>
	<navBar />
    <Loader v-if="loading"/>
	<div class="body">
        <div class="notif-post border-radius box-shadow" style="margin-top:80px">
            <div class="col-12">
                <div class="d-flex p-3" style="position:relative">
                        <div class="users" >
                            <img v-if="!data.owner_dp" 
                                height="40" width="40"
                                class="raidus-100"
                                @click="gotoProfile(data)"
                                src="@/assets/images/profiles/default.png" >
                            <img
                                v-if="data.owner_dp"
                                :src="`${image_api}/${data.owner_dp}`"
                                height="40" width="40"
                                @click="gotoProfile(data)"
                                class="raidus-100"
                            />
                            <b-dropdown
                                right
                                menu-class="dropdown-menu-sm p-0 dropdown-menu-end"
                                toggle-class="header-item noti-icon"
                                variant="black"
                                style="position: absolute; right:10px;top:0;" 
                            >
                                <template v-slot:button-content>
                                    <i class="bx bx-dots-horizontal-rounded px-1 fw-bold fs-5"></i>
                                </template>
                                <!-- item-->
                                <div class="p-2">
                                    <button class="dropdown-item text-dark p-2 fw-bold" @click="reportUser(data)">
                                        <i
                                            class="bx bxs-megaphone font-size-16 align-middle me-1"
                                        ></i>
                                        Report
                                    </button>
                                    <button v-if="profile.username == data.owner" class="dropdown-item text-dark p-2 fw-bold" @click="getPostData(data)">
                                        <i
                                            class="bx bxs-pencil font-size-16 align-middle me-1"
                                        ></i>
                                        Update
                                    </button>
                                    <button v-if="profile.username == data.owner" class="dropdown-item text-danger p-2 fw-bold" @click="deletePost(data)">
                                        <i
                                            class="bx bxs-trash font-size-16 align-middle me-1"
                                        ></i>
                                        Delete
                                    </button>
                                </div>
                            </b-dropdown>
                        </div>
                        <div>
                            <span class="mx-3" style="color: #050505" @click="gotoProfile(data)">{{data.owner}}</span>
                            <p class="mx-3" style=" color:#999999; font-size: 10px;">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</p>
                            <h6 class="mx-3" v-if="data.text != 'null'">{{ data.text}}</h6>
                            <!-- <div v-if="data.media">
                                <div class="image-container" v-if="parsedMedia(data.media).length > 0" >
                                    <img @click="postView(image)" v-for="(image, index) in parsedMedia(data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`" class="media-image cursor-pointer" height="250" width="250"/>
                                </div>
                            </div> -->
                            <div v-if="data.media">
                                <div class="image-container" v-if="parsedMedia(data.media).length > 0" >
                                    <div v-for="(image, index) in parsedMedia(data.media)" :key="index" class="media-item"> 
                                        <img @click="postView(image)" v-if="image.includes('.jpg'||'.jpeg'||'.png'||'.gif')" :src="getMediaUrl(image)" class="cursor-pointer" height="250" width="250"/>
                                        <video
                                            v-if="image.includes('.mp4'||'.webm'||'.ogg')"
                                            :src="getMediaUrl(image)"
                                            controls
                                            height="250"
                                            width="250"
                                        ></video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="justify-content-between px-5 pb-2" style="display:flex; align-items:center;">
                        <div class="text-start" v-if="data.reactions">
                            <!-- <span style="color: #999999" v-if="data.reactions.length > 0">
                                {{`${data.reactions.length} ${data.reactions.length > 1 ? 'reactions': 'react'}`}}
                            </span> -->
                            <div class="text-start" v-if="data.reactions_summary">
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.like > 0 ? data.reactions_summary.like + ' 👍 ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.haha > 0 ? data.reactions_summary.haha + ' 😆 ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.heart > 0 ? data.reactions_summary.heart + ' ❤️ ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.wow > 0 ? data.reactions_summary.wow + ' 😮 ': ''}`}}
                                </span>
                            </div>
                        </div>
                        <div class="text-start" v-if="data.comments">
                            <span style="color: #999999" v-if="data.comments.length > 0">
                                {{`${data.comments.length} ${data.comments.length > 1 ? 'comments': 'comment'}`}}
                            </span>
                        </div>
                    </div>
                    <div class="like-comment text-center d-flex px-2">
                        <div>
                            <!-- <i class="bx bx-like p-0 mb-0 fs-5 mx-2"></i><span class="fs-5">Like</span> -->
                             <div class="reaction-button-container" @click="toggleReactions(i,data)">
                                <i class="reaction-button post-buttons bx bx-like p-0 mb-0 fs-5 mx-2"></i><span class="fs-5 post-buttons">Like</span>
                                <div v-if="reactionsVisible == i && toggle" class="reactions">
                                    <div class="reaction" v-for="reaction in reactions" :key="reaction" @click="postReaction(data,$event)">
                                        {{ reaction }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <i class="bx bx-message fs-5 post-buttons mx-2"></i><span class="fs-5 post-buttons">Comment</span>
                        </div>
                        <div  @click="sendTo(data)" :hidden="profile.username == data.owner">
                            <i class="bx bx-send fs-5 post-buttons mx-2"></i><span class="fs-5 post-buttons">Send</span>
                        </div>
                    </div>
                    <div>
                        <div v-if="data.comments.length">
                            <div  class="comment-section d-flex px-3 pt-3"  v-for="(data, index) in data.comments" :key="index">
                                <img v-if="!data.owner_dp"  class="profile-picture" src="@/assets/images/profiles/default.png" >
                                <img
                                    v-if="data.owner_dp"
                                    :src="`${image_api}/${data.owner_dp}`"
                                    height="40" width="40"
                                    class="raidus-100"
                                />
                                <div class="comment-details mx-3">
                                
                                    <div class="bubble" style="position: relative;">
                                        <h6 class="fw-bold mb-0">{{data.owner}}</h6>
                                        {{ data.text}}
                                        <b-dropdown
                                            right
                                            menu-class="dropdown-menu-sm p-0 dropdown-menu-end"
                                            toggle-class="header-item noti-icon"
                                            variant="black"
                                            style="position: absolute; right:5px;top:-10px;" 
                                        >
                                            <template v-slot:button-content>
                                                <i class="bx bx-dots-horizontal-rounded px-1 fw-bold fs-5"></i>
                                            </template>
                                            <!-- item-->
                                            <div class="p-2">
                                                <button class="dropdown-item text-dark p-2 fw-bold" @click="reportUser(data)">
                                                    <i
                                                        class="bx bxs-megaphone font-size-16 align-middle me-1"
                                                    ></i>
                                                    Report
                                                </button>
                                                <button v-if="profile.username == data.owner" class="dropdown-item text-danger p-2 fw-bold" @click="commentDelete(data)">
                                                    <i
                                                        class="bx bxs-trash font-size-16 align-middle me-1"
                                                    ></i>
                                                    Delete
                                                </button>
                                            </div>
                                        </b-dropdown>
                                    </div>
                                    <div style="color: #999999;font-size: 12px;">
                                        <span class="px-3">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</span>
                                        <!-- <span class="px-3"><strong>Like</strong></span> -->
                                        <span class="px-3" @click="getIndex(index)" style="cursor:pointer;"><strong>Reply</strong></span>
                                        <div v-if="data.sub_comments.length > 0">
                                            <div  class="comment-section d-flex px-3 pt-3"  v-for="(data, i) in data.sub_comments" :key="i" >
                                                <img v-if="!data.owner_dp"  class="profile-picture" src="@/assets/images/profiles/default.png" >
                                                <img
                                                    v-if="data.owner_dp"
                                                    :src="`${image_api}/${data.owner_dp}`"
                                                    height="40" width="40"
                                                    class="raidus-100"
                                                />
                                                <div class="comment-details mx-3">
                                                    <div class="bubble">
                                                        <h6 class="fw-bold mb-0">{{data.owner}}</h6>
                                                        <span class="text-dark mb-0" >{{data.text}}</span>
                                                    </div>
                                                    <div style="color: #999999;font-size: 12px;">
                                                        <span class="px-3">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</span>
                                                        <!-- <span class="px-3"><strong>Like</strong></span> -->
                                                        <span class="px-3" @click="getReplyData(data,index)" style="cursor:pointer;"><strong>Reply</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment d-flex p-3" v-if="this.reply_index == index && reply_toggle == true">
                                            <i v-if="!this.profile.image"  class="bx bx-user comment-profile"></i>
                                            <img
                                                v-if="this.profile.image"
                                                :src="`${image_api}/${this.profile.image}`"
                                                height="32" width="32"
                                                class="raidus-100"
                                            />
                                            <input :ref="`replyInput${index}`" placeholder="Comment.." maxlength="50" @keyup.enter="replySubmit($event,data,index)" :value="this.reply_owner"/>
                                            <i class="bx bx-send send-icon" @click="replyComment(data, index)"></i>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="comment d-flex p-3">
                        <img v-if="!this.profile.image" 
                            height="32" width="32"
                            class="raidus-100"
                            src="@/assets/images/profiles/default.png" >
                        <img
                            v-if="this.profile.image"
                            :src="`${image_api}/${this.profile.image}`"
                            height="32" width="32"
                            class="raidus-100"
                        />
                        <input ref="commentInput" placeholder="Comment.." maxlength="50" @keyup.enter="commentSubmit($event,data,i)"/>
                        <i class="bx bx-send send-icon" @click="submitComment(data, i)"></i>
                    </div>
            </div>
        </div>
        <!-- MODALS -->
        <b-modal 
            v-model="update_post_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Update Post" 
            centered
            >
            <div class="d-flex">
                <div class="users">
                    <div class="">
                        <img v-if="!this.profile.image" 
                            height="40" width="40"
                            class="raidus-100"
                            src="@/assets/images/profiles/default.png" >
                        <img
                            v-if="this.profile.image"
                            :src="`${image_api}/${this.profile.image}`"
                            height="40" width="40"
                            class="raidus-100"
                        />
                    </div>
                </div>
                <div class="mx-3">
                    <span style="color: #050505">{{profile.username}}</span>
                    <p style=" color:#999999; font-size: 14px;">Public</p>
                </div>
            </div>
            <textarea class="form-control mb-3 write-something-input" placeholder="Write something..." v-model="pl_post_update.text">
            </textarea>
            <input
                type="file"
                ref="uploadFile"
                multiple 
                class="form-control mb-3" 
                @change="handleFileSelectUpdate" accept="image/*,video/*"
                />
            <div class="text-end mt-3">
                <b-button class="mx-1 w-100" variant="info" @click="updatePost()">Post</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import navBar from "@/components/nav-bar.vue";
import '@/assets/css/user-dashboard.css';
import { mapActions,mapGetters } from "vuex";
import formatter from '@/mixins/formatter';
import Loader from '@/components/widgets/loader.vue';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
			data:{
                comments:[],
            },
            reply_owner: null,
            reply_index: null,
            reply_toggle: false,
            reactionsVisible: null,
            reaction_id: null,
            toggle: false,
            reactions: ['👍','😆','❤️', '😮'],
            selectedUpdateMedia: [],
            update_post_modal: false,
            loading: false,
            pl_post_update:{
                text: null,
                reaction: null,
                id: '',
            },
            image_api: process.env.VUE_APP_BACKEND_API_IMAGES,
        };
    },
    mixins:[formatter],
	components: {
		navBar,Loader
	},
	computed: {
        ...mapGetters('auth', ['profile']),
	},
    methods:{
		...mapActions("posts", {
			createPost: "createPosts",
			showData: "showPost",
			reactionPost: "postReaction",
			editPost: "postEdit",
			removePost: "postRemove",
		}),
		...mapActions("comment", {
			addComment: "createComment",
			deleteComment: "removeComment",
			getComment: "getComments",
			addReplies: "addReply",
		}),
        async initList(p) {
			var pl = {
				page: p,
                id: this.$route.query.id
			};
            this.loading = true
			const data = await this.showData(pl);
            this.loading = false;
            this.data = data;
		},
        // RESPONSE MEDIA
        parsedMedia(media) {
            try {
                return JSON.parse(media);
            } catch (error) {
                return [];
            }
        },
        getMediaUrl(filename) {
            return `${this.image_api}/${filename}`;
        },
        getPostData(data){
            this.update_post_modal = true;
            this.pl_post_update.id = data._id
        },
        handleFileSelectUpdate(event) {
            this.selectedUpdateMedia = Array.from(event.target.files);
        },
        async updatePost(){
            const fd = new FormData();
            this.selectedUpdateMedia.forEach((file, index) => {
                fd.append(`media[${index}]`, file);
            });
            fd.append('text', this.pl_post_update.text);
            fd.append('id', this.pl_post_update.id);
            var pl = {
                formdata: fd,
                id: this.pl_post_update.id
            }
            this.update_post_modal = false;
            this.loading = true;
            const res = await this.editPost(pl);
            this.loading = false;
            if (res.status == 200|| res.status == 'success') {
                this.initList(1);
            }
        },
        async deletePost(data){
            var pl = {
                id: data._id,
            }
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to DELETE this?',
                html: `<table class="table table-bordered table-sm font-size-12 text-start">
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <td>${data.text}</td>
                                </tr>
                            </tbody>
                        </table>`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                const res = await this.removePost(pl);
                if (res.status == 200|| res.status == 'success') {
                    Swal.fire({
                        title: "Success",
                        html: `Deletion Successful.`,
                        type: "success",
                        icon: "success",
                        padding: "2em",
                    });
                    this.initList(1);
                }else{
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
        async commentSubmit(event,data){
            const fd = new FormData();
            fd.append('text', event.target.value);
            fd.append('post_id', data._id);
            this.post_id =data._id;
            const success = await this.addComment(fd);
            if(success.status == 200 || success.status == 'success'){
                this.initList(1);
            }
            event.target.value = ''
        },
        submitComment(data, i) {
            const commentInput = this.$refs.commentInput;
            const event = { target: commentInput };
            this.commentSubmit(event, data, i);
        },
        async commentDelete(data){
            var pl = {
                id: data._id,
            }
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to DELETE this?',
                html: `<table class="table table-bordered table-sm font-size-12 text-start">
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <td>${data.text}</td>
                                </tr>
                            </tbody>
                        </table>`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                const res = await this.deleteComment(pl);
                if (res.status == 200|| res.status == 'success') {
                    Swal.fire({
                        title: "Success",
                        html: `Deletion Successful.`,
                        type: "success",
                        icon: "success",
                        padding: "2em",
                    });
                    this.initList(1);
                }else{
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
        getIndex(index){
            this.reply_index = index;
            if(this.reply_toggle == false){
                this.reply_toggle = true;
            }else{
                this.reply_toggle = false;
            }
        },
        getReplyData(data,index){
            this.reply_owner = `${data.owner}: `
            this.reply_index = index;
            if(this.reply_toggle == false){
                this.reply_toggle = true;
            }else{
                this.reply_toggle = false;
                this.reply_owner = ""
            }
        },
        async replySubmit(event,data,index){
            this.comment_input = index
            const fd = new FormData();
            fd.append('text', event.target.value);
            fd.append('comment_id', data._id);
            this.comment_id = data._id
            const success = await this.addReplies(fd);
            if(success.status == 200 || success.status == 'success'){
                this.initList(1);
            }
            event.target.value = ''
        },
        replyComment(data, index) {
            const replyInput = this.$refs['replyInput' + index][0];
            const event = { target: replyInput };
            this.replySubmit(event, data, index);
        },
        toggleReactions(i,data) {
            this.reactionsVisible = i;
            this.reaction_id = data._id;
            if(this.toggle == false){
                this.toggle = true
            }else{
                this.toggle = false;
            }
        },
        async postReaction(data,event){
            var pl = {
                post_id: data._id,
            }
            if(event.target.innerHTML == '👍'){
                pl['type'] = 'like'
            }
            if(event.target.innerHTML == '😆'){
                pl['type'] = 'haha'
            }
            if(event.target.innerHTML == '❤️'){
                pl['type'] = 'heart'
            }
            if(event.target.innerHTML == '😮'){
                pl['type'] = 'wow'
            }
           await this.reactionPost(pl);
           this.reactionsVisible = null
        },
        postView(data){
            this.post_view_modal = true;
            this.post_view_media = data;
        },
    },
    mounted(){
        setTimeout(() => {
            this.initList(1)
        }, 1000);
    }
};
</script>
<style scoped>

.reaction-button-container {
  position: relative;
  display: inline-block;
}

.reactions {
  display: flex;
  position: absolute;
  top: -70px;
  left: -30px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

.reaction {
  font-size: 24px;
  padding: 5px;
  margin: 0 2px;
  cursor: pointer;
  transition: transform 0.2s;
}

.reaction:hover {
  transform: scale(1.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>